<template>
  <step-activity-layout :title="$t('module2.part1.activity.title')" :activity-finished="isGameFinished" :activity-started="isGameStarted">
    <template v-slot:intro>
      <activity-intro @start="isGameStarted = true"
        :title="$t('module2.part1.activity.intro.title')"
        :rules-content="$t('module2.part1.activity.intro.rules')"
        :rules-image="require('@/assets/module2/part1/activity-intro-illu.svg')"
      >
      </activity-intro>
    </template>
    <template v-slot:content>
      <quizz-activity v-if="!isGameFinished"
        :answers="answers"
        :questions="questions"
        :enable-correction="true"
        @quizz-finished="quizzFinished"
        @question-index-updated="onQuestionUpdated">
        <template v-slot:top="{ questionIndex, questionsCount }">
          <div class="quizz-top">
            <p class="title">{{ $t('module2.part1.activity.quizzTitle') }}</p>
            <p class="page-index">
              {{ questionIndex }} / {{ questionsCount }}
            </p>
          </div>
        </template>
        <template v-slot:answer="{ label, isRight, isChecked, showAnswers }">
          <p class="answer-wrapper" :class="{ right: isRight, checked: isChecked, 'show-answers': showAnswers }">{{ label }}</p>
        </template>
        <template v-slot:correction="{ correction }">
          <p class="correction" v-html="correction"></p>
        </template>
      </quizz-activity>
      <activity-result-block v-else @replay-activity="replayActivity" :is-custom-content="true">
        <template v-slot:content>
          <img class="result-image" :src="require('@/assets/module2/part1/activity-intro-illu.svg')">
        </template>
      </activity-result-block>
    </template>
  </step-activity-layout>
</template>

<script>
import StepActivityLayout from '@/components/training/StepActivityLayout'
import ActivityIntro from '@/components/training/ActivityIntro'
import useActivityUtils from '@/utils/useActivityUtils'
import QuizzActivity from '@/components/training/QuizzActivity'
import ActivityResultBlock from '@/components/training/ActivityResultBlock'

export default {
  name: 'Module2Part1Activity',
  components: { StepActivityLayout, ActivityIntro, QuizzActivity, ActivityResultBlock },
  setup () {
    const { isGameStarted, isGameFinished, replayActivity, onQuestionUpdated, onActivityFinished } = useActivityUtils()
    return { isGameFinished, isGameStarted, replayActivity, onQuestionUpdated, onActivityFinished }
  },
  data () {
    return {
      questions: [
        {
          name: 'question1',
          label: this.$t('module2.part1.activity.questions.question1'),
          image: require('@/assets/module2/part2/m2p1-bg-question-1.svg'),
          rightAnswer: ['pncee'],
          correction: this.$t('module2.part1.activity.corrections.correction1')
        },
        {
          name: 'question2',
          label: this.$t('module2.part1.activity.questions.question2'),
          image: require('@/assets/module2/part2/m2p1-bg-question-2.svg'),
          rightAnswer: ['oblige'],
          correction: this.$t('module2.part1.activity.corrections.correction2')
        },
        {
          name: 'question3',
          label: this.$t('module2.part1.activity.questions.question3'),
          image: require('@/assets/module2/part2/m2p1-bg-question-3.svg'),
          rightAnswer: ['mo'],
          correction: this.$t('module2.part1.activity.corrections.correction3')
        },
        {
          name: 'question4',
          label: this.$t('module2.part1.activity.questions.question4'),
          image: require('@/assets/module2/part2/m2p1-bg-question-4.svg'),
          rightAnswer: ['mo'],
          correction: this.$t('module2.part1.activity.corrections.correction4')
        },
        {
          name: 'question5',
          label: this.$t('module2.part1.activity.questions.question5'),
          image: require('@/assets/module2/part2/m2p1-bg-question-5.svg'),
          rightAnswer: ['pro', 'oblige'],
          correction: this.$t('module2.part1.activity.corrections.correction5')
        },
        {
          name: 'question6',
          label: this.$t('module2.part1.activity.questions.question6'),
          image: require('@/assets/module2/part2/m2p1-bg-question-6.svg'),
          rightAnswer: ['pro', 'mo'],
          correction: this.$t('module2.part1.activity.corrections.correction6')
        }
      ],
      answers: [
        {
          name: 'pncee',
          label: this.$t('module2.part1.activity.answers.pncee'),
          big: true
        },
        {
          name: 'oblige',
          label: this.$t('module2.part1.activity.answers.oblige'),
          big: true
        },
        {
          name: 'pro',
          label: this.$t('module2.part1.activity.answers.pro'),
          big: true
        },
        {
          name: 'mo',
          label: this.$t('module2.part1.activity.answers.mo'),
          big: true
        }
      ]
    }
  },
  methods: {
    quizzFinished () {
      this.onActivityFinished()
    }
  }
}
</script>

<style lang="scss" scoped>
.result-image {
  width: 100%;
}
.training-warning {
  margin-top: $space-s;
  color: $c-text;
}
.quizz-top {
  position: relative;
  font-size: $fz-xs;
  font-weight: $fw-l;
  .title {
    text-align: center;
    color: $c-primary;
    text-transform: uppercase;
  }
  .page-index {
    position: absolute;
    right: 0;
    top: 50%;
    transform: translateY(-50%);
    color: #fff;
    background-color: $c-text-lighter;
    border-radius: $radius;
    padding: 3px $space-s;
  }
}
.answer-wrapper {
  text-align: center;
  color: $c-text;
  font-weight: $fw-m;
  &.show-answers {
    &.right {
      color: $c-primary;
      font-weight: $fw-l;
      animation-name: win;
      animation-duration: 1.2s;
    }
  }
}
</style>
